import config from 'config';
import cc from 'store/util/createReduxConstant';
import createRequestAction from 'store/util/createRequestAction';
import { getAccessToken } from 'lib/tokenRegistry/auth0Provider';
import { BaseAction, Dispatch } from 'store/types';
import { createRequestReducer } from 'store/util/createRequestReducer';
import { createRequestConstantNames } from 'store/util/createConstants';
import { notifyError } from 'store/modules/toasts/actions';

import { Location } from '../types';

const [
  FETCH_SI_LOCATION,
  FETCH_SI_LOCATION_SUCCESS,
  FETCH_SI_LOCATION_FAILURE,
] = createRequestConstantNames(cc('FETCH_SI_LOCATION'));

const [
  UPDATE_SI_LOCATION,
  UPDATE_SI_LOCATION_SUCCESS,
  UPDATE_SI_LOCATION_FAILURE,
] = createRequestConstantNames(cc('UPDATE_SI_LOCATION'));

const handleError = (payload, dispatch) => {
  payload.errors?.forEach(err => dispatch(notifyError(err.message)));
  return payload;
};

const locationQuery = `query GetLocation($id: ID!) {
  location(id: $id, type: SPACEMAN) {
      id,
      name,
      openDate,
      memberEntranceInstructions,
      parkingInstructions,
      tourInstructions,
      communityBarFloor {
       name
      },
       addresses {
         type,
         line1,
         line2,
         line3,
         dependentLocality,
         locality,
         administrativeArea,
         postalCode,
         latitude
         longitude
         countryISO3
      },
      identifiers {
        type,
        value,
      },
      buildings {
          id,
          floors {
              id,
              name,
              status,
          }
      },
      defaultAddress {
          type,
          line1,
          line2,
          line3,
          dependentLocality,
          locality,
          administrativeArea,
          postalCode,
          countryISO3,
          country,
          latitude,
          longitude
      },
      weeklyHours {
          type,
          dailyHours {
              day,
              openTime,
              closeTime
          }
      },
      amenities {
          id,
          name,
          type,
      }
      images {
        url,
        category
      }
      isMigrated
      status
      franchise
  }
}`;

const locationUpdateQuery = `mutation UpdateLocation($id: ID!, $data: LocationUpdateInput!) {
  updateLocation(id: $id, data: $data) {
    data {
      locationID
    }
  }
}`;

export const fetchLocationBySpacemanId = (id: string) => async (dispatch: Dispatch<BaseAction>) => {
  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: `${config.siGateway.uri}`,
    types: [
      { type: FETCH_SI_LOCATION },
      { type: FETCH_SI_LOCATION_SUCCESS },
      { type: FETCH_SI_LOCATION_FAILURE },
    ],
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'apollographql-client-name': 'spacestation-web',
    },
    body: {
      query: locationQuery,
      variables: {
        id,
      },
    },
    payloadFromJSON: json => handleError(json, dispatch),
  });
  return dispatch(requestAction);
};

export const updateLocationBySpacemanId = (id: string, data: LocationInput) => async (
  dispatch: Dispatch<BaseAction>
) => {
  const requestAction = createRequestAction({
    method: 'POST',
    endpoint: `${config.siGateway.uri}`,
    types: [UPDATE_SI_LOCATION, UPDATE_SI_LOCATION_SUCCESS, UPDATE_SI_LOCATION_FAILURE],
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'apollographql-client-name': 'spacestation-web',
    },
    body: {
      query: locationUpdateQuery,
      variables: {
        id,
        data,
      },
    },
    payloadFromJSON: json => handleError(json, dispatch),
  });
  return dispatch(requestAction);
};

interface LocationResponse {
  data: {
    location: Location;
  };
}

interface AddressInput {
  type: string;
  line1: string;
  city: string;
  state?: string;
  postalCode: string;
  countryISO2: string;
}

interface LocationInput {
  addresses?: [AddressInput];
  memberEntranceInstructions?: string;
  parkingInstructions?: string;
  communityBarFloorName?: string;
}

const reducer = createRequestReducer<Location | null, LocationResponse>(
  [FETCH_SI_LOCATION, FETCH_SI_LOCATION_SUCCESS, FETCH_SI_LOCATION_FAILURE],
  null,
  res => res.payload?.data?.location
);

export default reducer;
