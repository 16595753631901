import React, { useState, useRef, useMemo, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Icon, IconName, IconSize, IconType, Tag } from '@wework/ray2';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import config from 'config';
import Search from 'features/search';
import LocationPicker from 'features/locationPicker';
import Flag from 'components/flag';
import LoadingPage from 'components/loadingPage';
import ModalPortal from 'components/modals/portal';
import MemberNotificationsModalManager from 'features/communication/memberNotifications/modals/memberNotificationsModalManager';
import { getExperiments } from 'store/middlewares/experimentsHelper';
import { getCurrentLocation } from 'store/modules/siLocations/selectors';
import { getSpacemanId } from 'store/modules/siLocations/utils';
import { GlobalState } from 'store/modules';
import AnalyticsProvider from 'lib/contextualAnalytics/provider';
import { EmployeePermissions } from 'lib/permissions';
import { WithRequestedAuthz } from 'features/app/authorization';
import AvatarMenu from 'features/search/components/avatarMenu';
import NotificationsContainer from 'features/notifications/components/notificationsContainer';
import NotificationAlert from 'features/notifications/components/notificationAlert';
import { LOCATION_STATUS_TO_TAG_MAPPING, LocationStatus } from 'store/modules/siLocations';
import MigratedToKubeTag from 'components/migratedToKubeTag';

import SideNav from './sideNav';
import ContentArea from './contentArea';
import SingleSpa from './singleSpa';
import styles from './index.scss';
import { MissionControlBanner } from './singleSpa/missionControlBanner';
import { MC_OPERATOR_ENDPOINTS } from './singleSpa/constants';

type OwnProps = Readonly<{
  children: React.ReactNode;
  loading: boolean;
  notificationSystem: React.ReactNode;
  shouldRenderVersionFlag: boolean;
  showSideNav: boolean;
}>;

const mapStateToProps = (state: GlobalState) => ({
  currentLocation: getCurrentLocation(state),
  experimentGroups: getExperiments(state),
});

export type Props = Readonly<OwnProps & RouteComponentProps & ReturnType<typeof mapStateToProps>>;

export const ContentContainerRefContext = React.createContext<{ current: null | HTMLDivElement }>({
  current: null,
});

const formatMiddleEllipsis = (str: string | undefined) => {
  if (str! && str.length > 25) {
    return `${str.substr(0, 11)}…${str.substr(str.length - 11, str.length)}`;
  }
  return str;
};

const LocationName: React.FC<Pick<Props, 'currentLocation'>> = ({ currentLocation }) => {
  const locationStatus =
    LOCATION_STATUS_TO_TAG_MAPPING[currentLocation?.status || LocationStatus.Unknown];

  return (
    <span className="ml-2xs text-3xs font-main text-black p-0 max-w-[300px]">
      {formatMiddleEllipsis(currentLocation?.name) || ''}
      <Tag theme={locationStatus.theme}>{locationStatus.title}</Tag>
      {!isEmpty(currentLocation) && (
        <MigratedToKubeTag
          locationId={currentLocation.id}
          isMigrated={currentLocation.isMigrated}
          franchise={currentLocation?.franchise}
        />
      )}
    </span>
  );
};

const AppLayout = (props: Props) => {
  const {
    children,
    currentLocation,
    experimentGroups,
    loading,
    location,
    notificationSystem,
    shouldRenderVersionFlag,
    showSideNav,
  } = props;

  const [isBannerOpen, setIsBannerOpen] = useState(false);

  useEffect(() => {
    const isOpSrvRoute = MC_OPERATOR_ENDPOINTS.some(route => route === location.pathname);
    setIsBannerOpen(isOpSrvRoute);
  }, [location?.pathname]);

  const analyticsData = useMemo(
    () => ({ location_uuid: currentLocation ? getSpacemanId(currentLocation) : undefined }),
    [currentLocation]
  );

  const contentContainerRef = useRef<HTMLDivElement>(null);
  const [isLocationPickerOpen, setLocationPickerOpen] = useState(false);
  const openLocationPicker = () => setLocationPickerOpen(true);
  const closeLocationPicker = () => setLocationPickerOpen(false);

  const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(false);

  const content = loading ? <LoadingPage invert /> : React.Children.only(children);

  if (showSideNav) {
    return (
      <AnalyticsProvider data={analyticsData}>
        <div className={styles.wrapper}>
          <div className={styles.navigation}>
            <WithRequestedAuthz
              permissions={[
                EmployeePermissions.account_service_view_all_dns_verifications,
                EmployeePermissions.id_scim_manage_sso_configs,
                EmployeePermissions.id_scim_manage_tenants,
                EmployeePermissions.id_view_contractors,
                EmployeePermissions.opsrv_manage_operators,
                EmployeePermissions.opsrv_manage_roles_and_permissions,
                EmployeePermissions.opsrv_read_service_clients,
                EmployeePermissions.opsrv_read_service_permissions,
                EmployeePermissions.opsrv_view_employees,
                EmployeePermissions.opsrv_view_operators,
                EmployeePermissions.opsrv_view_roles_and_permissions,
                EmployeePermissions.spacestation_floor_plan_asset_download,
                EmployeePermissions.spacestation_floor_plan_view,
                EmployeePermissions.spacestation_security_only_access,
                EmployeePermissions.update_employee_roles,
                EmployeePermissions.update_occupiable_pricing_capacity,
                EmployeePermissions.view_occupancy_info,
                EmployeePermissions.virtual_reservable_matching,
              ]}
            >
              <SideNav
                currentLocation={currentLocation}
                experimentGroups={experimentGroups}
                loading={loading}
                requestedPermissions={{}}
                requestedRoles={{}}
              />
            </WithRequestedAuthz>
          </div>
          <ContentContainerRefContext.Provider value={contentContainerRef}>
            <div className={styles.content} ref={contentContainerRef}>
              {notificationSystem}
              <NotificationAlert />
              <ContentArea>
                {content}
                <SingleSpa />
              </ContentArea>
              {shouldRenderVersionFlag && <Flag text={config.appEnv} />}
              <div
                className={cn(
                  'flex fixed items-center top-0 left-[228px] right-0 pt-4 pr-0 my-0 mr-10 ml-1 h-15 box-content',
                  isGlobalSearchOpen ? 'z-[1000]' : 'z-[50]'
                )}
              >
                <div>
                  <Search setIsGlobalSearchOpen={setIsGlobalSearchOpen} loading={loading} />
                </div>
                <div className="flex ml-auto relative z-20">
                  <div
                    onClick={openLocationPicker}
                    className="cursor-pointer rounded border border-gray-80 flex self-center h-[65px] items-center pr-sm pl-2xs mr-2xs max-w-[350px] hover:bg-gray-95 active:bg-gray-90"
                  >
                    <Icon
                      icon={IconName.PIN}
                      size={IconSize.SMALL}
                      type={IconType.OUTLINE}
                      className="text-black overflow-hidden"
                    />
                    <LocationName currentLocation={currentLocation} />
                  </div>
                  <NotificationsContainer />
                  <AvatarMenu className="!ml-1 !-mr-2 -mt-3xs self-center" loading={loading} />
                </div>
              </div>
            </div>
          </ContentContainerRefContext.Provider>
          <LocationPicker isOpen={isLocationPickerOpen} closeLocationPicker={closeLocationPicker} />
          {!loading && (
            <ModalPortal>
              <MemberNotificationsModalManager />
            </ModalPortal>
          )}
          {isBannerOpen && (
            <MissionControlBanner
              onDismiss={() => setIsBannerOpen(false)}
              pathname={location.pathname}
            />
          )}
        </div>
      </AnalyticsProvider>
    );
  }

  return (
    <div className={styles.noSideNav}>
      {notificationSystem}
      <SingleSpa />
      {content}
    </div>
  );
};

export default compose<React.ComponentType<OwnProps>>(
  withRouter,
  connect(mapStateToProps)
)(AppLayout);
