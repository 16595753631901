import React, { useMemo } from 'react';
import { Tag } from '@wework/dieter-ui';
import cn from 'classnames';

import { getKubeUrl } from 'lib/util';

interface Props {
  isMigrated: boolean;
  isPartiallyMigrated?: boolean;
  locationId?: string;
  relativeUrl?: string;
  franchise?: string | null;
}

const MigratedToKubeTag = ({
  locationId,
  isMigrated = false,
  isPartiallyMigrated = false,
  relativeUrl,
  franchise,
}: Props) => {
  const kubeBaseUrl = useMemo(() => getKubeUrl(franchise), [franchise]);
  const kubeLocationLink =
    (locationId || relativeUrl) &&
    `${kubeBaseUrl}${relativeUrl || `/reseller/content/#/app/property/${locationId}`}`;

  let tagLabel = isMigrated ? 'migrated to kube' : 'partially migrated to kube';
  if (franchise && franchise !== 'global') tagLabel += ` - ${franchise}`;

  return (
    <div className={cn(['mt-1 z-50 relative', { 'pointer-events-none': !kubeLocationLink }])}>
      {(isMigrated || isPartiallyMigrated) && (
        <a href={kubeLocationLink || '#'} target="_blank" rel="noreferrer" className="mr-1">
          <Tag color="green">{tagLabel}</Tag>
        </a>
      )}
    </div>
  );
};

export default MigratedToKubeTag;
