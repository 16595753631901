import { Action } from 'redux-actions';

import { Reservation } from 'store/modules/occupancies/types';
import { Building } from 'store/modules/siLocations/types';

import { TeamMembers, InventoryItem } from '../types';

// add new filter displays here
export enum FilterType {
  Date = 'date',
  Location = 'location',
  MultiSelect = 'multiSelect',
  MultiSelectDropdown = 'multiSelectDropdown',
  None = 'none',
  Number = 'number',
  Range = 'range',
  Toggle = 'toggle',
  SingleSelect = 'singleSelect',
}

// add new filter keys here
export enum FilterKey {
  Attributes = 'attributes',
  BoundingBox = 'boundingBox',
  FuzzyCapacity = 'fuzzyCapacity',
  FilterableGates = 'filterableGates',
  Gates = 'gates',
  LocationUuids = 'locationUuids',
  MaxCapacity = 'maxCapacity',
  MaxPrice = 'maxPrice',
  MinCapacity = 'minCapacity',
  MinPrice = 'minPrice',
  ProductTypes = 'productTypes',
  SearchGroups = 'searchGroups',
  StartDate = 'startDate',
  Status = 'status',
  MaxSquareFootage = 'maxSquareFootage',
  MinSquareFootage = 'minSquareFootage',
  FloorIds = 'floorIds',
  Availability = 'availability',
  KeywordSearchedLocationDetails = 'keywordSearchedLocationDetails',
  LocationStatus = 'locationStatus',
  InMapViewLocationUuids = 'inMapViewLocationUuids',
}

export enum AvailabilityFilter {
  AVAILABLE_ONLY = 'AVAILABLE_ONLY',
  ALL_INVENTORY = 'ALL_INVENTORY',
}

export type SelectOptionFilterConfig = {
  label: string;
  value: string;
  info?: string;
};
export type SelectFilterConfig = { options?: Array<SelectOptionFilterConfig> };
export type DateFilterConfig = {
  minDate?: string;
  maxDate?: string;
};
export type NumberFilterConfig = {
  min?: number | null | undefined;
  max?: number | null | undefined;
};
export type RangeFilterConfig = {
  min?: number | null | undefined;
  max?: number | null | undefined;
};
export type BooleanFilterConfig = {
  defaultValue: boolean;
  label?: string;
};

// add new filter configs here
export type DefaultFilterConfig = BooleanFilterConfig &
  DateFilterConfig &
  SelectFilterConfig &
  NumberFilterConfig &
  RangeFilterConfig;

// add new filter and types here:
export type LatLng = {
  lat: number;
  lng: number;
};

export type BoundingBox = {
  northeast: LatLng;
  southwest: LatLng;
};

export type SortOrder = 'asc' | 'desc';
export type SortBy = {
  fieldName?: string;
  sortOrder?: SortOrder;
};

export type KeywordSearchedLocationMeta = {
  address: string;
  name: string;
};

export type FilterValue =
  | (string | null | undefined)
  | (number | null | undefined)
  | (Array<string> | null | undefined)
  | (BoundingBox | null | undefined)
  | (SortBy | null | undefined)
  | (boolean | null | undefined)
  | (KeywordSearchedLocationMeta | null | undefined);

export type Filters = {
  attributes?: Array<string> | null | undefined;
  filterableGates?: Array<string> | null | undefined;
  boundingBox?: BoundingBox;
  floorIds?: Array<string>;
  locationUuids?: Array<string>;
  maxCapacity?: number;
  maxFuzzyCapacity?: number;
  maxPrice?: number;
  minCapacity?: number;
  minFuzzyCapacity?: number;
  minPrice?: number;
  maxSquareFootage?: number;
  minSquareFootage?: number;
  productTypes?: Array<string> | null | undefined;
  searchGroups?: boolean | null | undefined;
  startDate?: string;
  availability?: Array<string> | null | undefined;
  sortBy?: SortBy | null | undefined;
  status?: Array<string> | null | undefined;
  keywordSearchedLocationDetails?: KeywordSearchedLocationMeta | null | undefined;
  locationStatus?: Array<string> | null | undefined;
  inMapViewLocationUuids?: Array<string>;
};

export type SIIdentifier = {
  type: string;
  value: string;
};

export type SISortBy = {
  field?: string;
  order?: string;
};

export type SIFilters = {
  attributes?: Array<string> | null | undefined;
  filterableGates?: Array<string> | null | undefined;
  boundingBox?: BoundingBox;
  floorIds?: Array<string>;
  locationIds?: Array<SIIdentifier>;
  maxCapacity?: number;
  maxFuzzyCapacity?: number;
  maxPrice?: number;
  minCapacity?: number;
  minFuzzyCapacity?: number;
  minPrice?: number;
  maxSquareFootage?: number;
  minSquareFootage?: number;
  productTypes?: Array<string> | null | undefined;
  isSearchGroups?: boolean | null | undefined;
  startDate?: string;
  availability?: Array<string> | null | undefined;
  status?: Array<string> | null | undefined;
  sortBy?: SISortBy | null | undefined;
  keywordSearchedLocationDetails?: KeywordSearchedLocationMeta | null | undefined;
  locationStatus?: Array<string> | null | undefined;
};

export type UserFilterPayload = {
  name: string;
  key: string;
  options: DefaultFilterConfig;
  default?: FilterValue;
};

export type UserFiltersResponse = { payload: Array<UserFilterPayload> };

export enum InventoryDisplayVariants {
  CartViewVariant = 'cartView',
  ComparisonViewVariant = 'comparisonView',
  CompactViewVariant = 'compactView',
  DrawerViewVariant = 'drawerView',
  Everything = '',
  GroupSummaryView = 'groupSummaryView',
  OfficePageViewVariant = 'officeView',
}

// add new attribute display here
export enum InventoryDisplayItemType {
  ACTIONS = 'actions',
  AVAILABILITY_ON = 'availabilityOn', // old availability calculation
  AVAILABILITY = 'availability', // new availability calculation
  BUILDING = 'building',
  BUTTON = 'button',
  CHECKED = 'checked',
  COMMUNITY_MANAGER = 'communityManager',
  CURRENT_MEMBER_DETAILS = 'currentMember',
  FLOOR_PLAN = 'floorPlan',
  FLOOR_PLAN_LINK = 'floorPlanLink',
  HEADER = 'header',
  HOLD_STATUS = 'holdStatus',
  LABELED_DATE = 'labeledDate',
  LOCATION = 'location',
  NEIGHBORING_MEMBERS = 'neighboringMembers',
  NOTES = 'notes',
  PAST_OCCUPANCIES = 'pastOccupancies',
  PRICE = 'price',
  PRICE_PER_RSF = 'pricePerRSF',
  PRODUCT = 'product',
  RSF = 'RSF',
  STRING = 'string',
  LINK = 'link',
  GROUP = 'group',
  TOTAL_CONTRACT_VALUE_OPTIONS = 'totalContractValueOptions',
  SQUARE_FOOTAGE = 'squareFootage',
  OVERFLOW_TAG = 'overflowTag',
}

export enum InventoryAvailability {
  AVAILABLE = 'available',
  LIMITED_AVAILABILITY = 'limited_availability',
  NOT_AVAILABLE = 'not_available',
}

export enum InventoryStatus {
  ASSIGNED = 'assigned',
  OCCUPIED = 'occupied',
}

export type Occupancy = { reservations?: Array<Reservation> };

export type Occupancies = { byOccupiableUuid: Hash<Occupancy> };

export type Hold = {
  active?: boolean;
  begins_at?: string;
  ends_at?: string;
  note?: string;
  uuid?: string;
};

export type CBPItem = {
  action?: string | null | undefined;
  capacity?: number | null | undefined;
  location_uuid?: string | null | undefined;
  office_type?: string | null | undefined;
  office?: string | null | undefined;
};

export type CBPItems = Array<CBPItem | null | undefined> | null | undefined;

export type ActionPayload = {
  available?: boolean | null | undefined;
  hold?: Hold | null | undefined;
  locationUuid?: string | null | undefined;
  locationCode?: string | null | undefined;
  name?: string | null | undefined;
  notes?: string | null | undefined;
  onHold?: boolean | null | undefined;
  paperworkPending?: boolean | null | undefined;
  type?: string | null | undefined;
  uuid?: string | null | undefined;
};

export type ModalTypes = 'addNoteModal' | 'officeHoldModal' | 'removeOfficeHoldModal';

export type InventoryFunctionAction = (
  payload: ActionPayload
) => Promise<{
  payload: ActionPayload;
  selectedModal: ModalTypes;
}>;

export type InventoryAction = (
  payload: ActionPayload | null | undefined
) => Action<{ payload: ActionPayload | null | undefined; selectedModal: ModalTypes }>;

export type IFrameInventoryAction = (payload: ActionPayload | null | undefined) => void;

export interface ActionConfig<
  T extends boolean = boolean,
  A extends (payload: ActionPayload | null | undefined) => any = (
    payload: ActionPayload | null | undefined
  ) => any
> {
  key: string;
  text: string;
  action: A;
  dispatch: T;
}

export type InventoryActionConfig = ActionConfig<true, InventoryAction>;

export type IFrameActionConfig = ActionConfig<false, IFrameInventoryAction>;

export type ActionAttributeMeta = {
  payload?: ActionPayload;
  actions?: Array<InventoryActionConfig | IFrameActionConfig>;
};
export type AvailabilityOnAttributeMeta = {
  availableDate?: string | null | undefined;
  estimate?: boolean | null | undefined;
  gate?: string | null | undefined;
  isAvailable?: boolean | null | undefined;
  showAvailableTodayText?: boolean | null | undefined;
  availability?: string | null | undefined;
};
export type FloorPlanAttributeMeta = {
  floorUuid?: string | null | undefined;
  locationUuid?: string | null | undefined;
  name?: string | null | undefined;
  physicalSpaceUuid?: string | null | undefined;
  reservableUuid?: string | null | undefined;
  type?: string | null | undefined;
  workUnits?: number | null;
};
export type HoldStatusAttributeMeta = {
  // this is ok since both payload are of the same type
  payload?: ActionPayload;
};
export type LocationAttributeMeta = {
  buildingUuid?: string | null | undefined;
  country?: string | null | undefined;
  displayType?: string | null | undefined;
  floor?: string | null | undefined;
  isGroup?: boolean;
  locationName?: string | null | undefined;
  name?: string | null | undefined;
  type?: string | null | undefined;
  isMigrated?: boolean | null | undefined;
  buildings?: Building[];
  franchise?: string | null;
};
export type PriceAttributeMeta = {
  cbpItems?: CBPItems | null | undefined;
  currency?: string | null | undefined;
  estimate?: boolean | null | undefined;
  price?: number | null | undefined;
  pricePerRSF?: number | null | undefined;
};

export type FloorPlanLinkAttributeMeta = {
  locationUuid?: string;
  type?: string | null | undefined;
  url?: string | null | undefined;
};

export type CurrentMemberAttributeMeta = {
  uuid?: string | null | undefined;
  locationUuid?: string | null | undefined;
  currencyCode?: string | null | undefined;
};

export type EstimatedAttributeMeta = { estimate?: boolean | null | undefined };

export type GroupItemsAttributesMeta = { items?: Array<InventoryItem> | null | undefined };

export type SquareFootageAttributesMeta = {
  removeFromDrawer?: boolean | null | undefined;
  rentableSquareFootage?: string | null | undefined;
};

export type InventoryTagsAttributesMeta = {
  tags?:
    | Array<{
        name: string;
        description: string;
      }>
    | null
    | undefined;
};

// add new attribute meta here
export type InventoryAttributeMeta = ActionAttributeMeta &
  AvailabilityOnAttributeMeta &
  CurrentMemberAttributeMeta &
  EstimatedAttributeMeta &
  FloorPlanAttributeMeta &
  FloorPlanLinkAttributeMeta &
  GroupItemsAttributesMeta &
  HoldStatusAttributeMeta &
  LocationAttributeMeta &
  PriceAttributeMeta &
  SquareFootageAttributesMeta &
  InventoryTagsAttributesMeta;

export type Floor = {
  name?: string | null | undefined;
  uuid?: string | null | undefined;
};

export type Location = {
  address1?: string | null | undefined;
  city?: string | null | undefined;
  country?: string | null | undefined;
  name?: string | null | undefined;
  type?: string | null | undefined;
  uuid?: string | null | undefined;
  code?: string | null | undefined;
  isMigrated?: boolean | null | undefined;
  buildings?: Building[];
};

export type Price = {
  amount?: number | null | undefined;
  currency?: string | null | undefined;
};

export type TotalContractValue = {
  selectedMonthValue: string | null | undefined;
  selectedPercentValue: string | null | undefined;
};

export type GeoLocation = {
  latitude: number;
  longitude: number;
};

// add new inventory payload item to serialize here
export type InventoryPayloadItem = {
  archivedDate?: string | null | undefined;
  available?: boolean | null | undefined;
  buildingClass?: string | null | undefined;
  buildingTier?: number | null | undefined;
  capacity?: number | null | undefined;
  displayType?: string | null | undefined;
  filterableGate?: string | null | undefined;
  floor?: Floor | null | undefined;
  geoLocation?: GeoLocation;
  hasWindow?: boolean | null | undefined;
  hold?: Hold | null | undefined;
  internalRoomCount?: number | null | undefined;
  items?: Array<InventoryPayloadItem> | null | undefined;
  location?: Location | null | undefined;
  name?: string | null | undefined;
  notes?: string | null | undefined;
  onHold?: boolean | null | undefined;
  openDate?: string | null | undefined;
  currentMoveIn?: string | null | undefined;
  currentMoveOut?: string | null | undefined;
  nextMoveIn?: string | null | undefined;
  paperworkPending?: boolean | null | undefined;
  physicalSpaceUuid?: string | null | undefined;
  price?: Price | null | undefined;
  squareFootage?: number | null | undefined;
  rentableSquareFootage?: number | null | undefined;
  seatCount?: number | null | undefined;
  stargateFloorUuid?: string;
  startDate?: string | null | undefined;
  statusDesc?: string | null | undefined;
  type?: string | null | undefined;
  usableSquareFootage?: number | null | undefined;
  uuid: string;
  workUnits?: number | null;
  availability?: string | null | undefined;
  inventoryTags?: Array<TagPayloadItem> | null | undefined;
  spatialWorkUnits?: number | null | undefined;
};

export type TagPayloadItem = {
  name: string;
  description: string;
};

export type BuildingPayloadItem = {
  id: string;
  latitude: string;
  longitude: string;
  name: string;
};

export interface Address {
  latitude: number;
  longitude: number;
}

export type IdentifierType = 'MLS' | 'SPACEMAN' | 'SPACEMAN_CODE';

export interface Identifier {
  type: IdentifierType;
  value: string;
}

export type BuildingPayloadItemLocation = {
  id: string;
  name: string;
  addresses: Address[];
  identifiers: Identifier[];
};

export type CommunityPayloadItem = {
  id: string;
  team_members: TeamMembers;
};
