// const enum (https://www.typescriptlang.org/docs/handbook/enums.html#const-enums)
// would have been better, however, it doesnt compile at the moment
import { TagColors } from '@wework/ray2';

export enum IdentifierType {
  SPACEMAN = 'SPACEMAN',
  SPACEMAN_CODE = 'SPACEMAN_CODE',
}

export interface Identifier {
  type: IdentifierType;
  value: string;
}

export interface Address {
  type: string;
  line1: string;
  line2?: string;
  line3?: string;
  locality: string; // city
  dependentLocality?: string; // neighborhood
  administrativeArea?: string; // state
  postalCode: string;
  countryISO3: string;
  country: string;
  latitude?: number;
  longitude?: number;
}

export interface Location {
  id: string;
  name: string;
  openDate?: string | null;
  localTimeZone: string;
  identifiers: Identifier[];
  defaultAddress: Address;
  defaultCurrency: string;
  defaultLocale: string;
  paperworkLocale?: string;
  supportEmail: string;
  isMigrated: boolean;
  buildings?: Building[];
  status: LocationStatus | null;
  franchise?: string | null;
}

export interface Building {
  floors: Floor[];
  status?: LocationStatus | null;
}

export interface Floor {
  id: string;
  name: string;
  status: FloorStatus | null;
}

export enum LocationStatus {
  Open = 'STATUS_OPEN',
  Vacant = 'STATUS_VACANT',
  PreOpen = 'STATUS_PRE_OPEN',
  UnderContract = 'STATUS_UNDER_CONTRACT',
  Dark = 'STATUS_DARK',
  Exiting = 'STATUS_EXITING',
  Exited = 'STATUS_EXITED',
  Unknown = 'STATUS_UNKNOWN',
}

export const LOCATION_STATUS_TO_TAG_MAPPING: Record<
  LocationStatus,
  { title: string; theme: TagColors }
> = {
  [LocationStatus.Dark]: {
    title: 'Dark',
    theme: TagColors.GRAY,
  },
  [LocationStatus.Exited]: {
    title: 'Exited',
    theme: TagColors.RED,
  },
  [LocationStatus.Exiting]: {
    title: 'Exiting',
    theme: TagColors.YELLOW,
  },
  [LocationStatus.Open]: {
    title: 'Open',
    theme: TagColors.GREEN,
  },
  [LocationStatus.PreOpen]: {
    title: 'Pre Open',
    theme: TagColors.TEAL,
  },
  [LocationStatus.UnderContract]: {
    title: 'Under Contract',
    theme: TagColors.LIME,
  },
  [LocationStatus.Unknown]: {
    title: 'Unknown',
    theme: TagColors.PURPLE,
  },
  [LocationStatus.Vacant]: {
    title: 'Vacant',
    theme: TagColors.WHITE,
  },
};

export enum FloorStatus {
  Unknown = 'FLOOR_STATUS_UNKNOWN',
  Open = 'FLOOR_STATUS_OPEN',
  Vacant = 'FLOOR_STATUS_VACANT',
  PreOpen = 'FLOOR_STATUS_PRE_OPEN',
  UnderContract = 'FLOOR_STATUS_UNDER_CONTRACT',
  Dark = 'FLOOR_STATUS_DARK',
  Exiting = 'FLOOR_STATUS_EXITING',
  Exited = 'FLOOR_STATUS_EXITED',
}

export const FLOOR_STATUS_TO_TAG_MAPPING: Record<
  FloorStatus,
  { title: string; theme: TagColors }
> = {
  [FloorStatus.Dark]: {
    title: 'Dark',
    theme: TagColors.GRAY,
  },
  [FloorStatus.Exited]: {
    title: 'Exited',
    theme: TagColors.RED,
  },
  [FloorStatus.Exiting]: {
    title: 'Exiting',
    theme: TagColors.YELLOW,
  },
  [FloorStatus.Open]: {
    title: 'Open',
    theme: TagColors.GREEN,
  },
  [FloorStatus.PreOpen]: {
    title: 'Pre Open',
    theme: TagColors.TEAL,
  },
  [FloorStatus.UnderContract]: {
    title: 'Under Contract',
    theme: TagColors.LIME,
  },
  [FloorStatus.Unknown]: {
    title: 'Unknown',
    theme: TagColors.PURPLE,
  },
  [FloorStatus.Vacant]: {
    title: 'Vacant',
    theme: TagColors.WHITE,
  },
};
