import { SearchableEntity } from 'features/search/types';
import { GuestVisit, CheckedInGuestVisit } from 'features/visitors/types';
import { SearchUser } from 'store/modules/accounts/types';

type LocationUuid = string;

export enum EntityType {
  COMPANY = 'company',
  MEMBER = 'member',
  KEYCARD = 'keycard',
  CONTACT = 'contact',
  GUEST = 'guest',
  BUILDING = 'building',
  // A "private member" is a user with PII in the Account Service and with the same PII redacted in ID
  PRIVATE_MEMBER = 'privateMember',
}

type GeoLocation = {
  lat: number;
  lng: number;
  radius: number;
};

export type Filters = {
  types?: Array<EntityType>;
  locations?: Array<LocationUuid>;
  active?: boolean;
  activeLicense?: boolean;
  optionalFilters?: Array<string> | null | undefined;
  ids?: Array<string>;
  geoLocation?: GeoLocation;
};

export type SearchRequest = {
  query?: string;
  limit: number;
  filters: Filters;
  facets?: Array<string>;
};

export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type Space = {
  office_number?: string;
  floor_number?: number;
  location_uuid: string;
};

export enum MemberRole {
  PRIMARY = 'super-admin',
  ADMIN = 'admin',
  BILLING = 'billing',
}

export type MemberRoleObject = {
  slug: MemberRole;
};

export type MimoInfo = {
  first_move_in_uuid: string;
  first_move_date: string;
};

export type Mimo = {
  [locationUuid: string]: MimoInfo;
};

export enum SystemRecordType {
  Wework = 'wework',
  Kube = 'kube',
  Hybrid = 'hybrid',
}

export type MemberCompany = {
  uuid: string;
  name: string;
  is_migrated: boolean;
  aliases?: string[];
  spaces: Array<Space>;
  status: Status;
  roles: Array<MemberRole | MemberRoleObject>;
  mimo?: Mimo;
  system_of_record?: SystemRecordType;
  franchise?: string | null;
};

export interface UserMembership {
  product_name: string;
  product_uuid: string;
  account_uuid: string;
  location_uuids: string[];
  started_on: string;
}

export type AlgoliaMember = {
  uuid: string;
  name: string;
  email: string;
  status: Status;
  kind: string;
  notes?: string;
  aliases: string[];
  company_names: string[];
  companies: MemberCompany[];
  past_companies?: MemberCompany[];
  has_business_address?: boolean;
  is_licensee: boolean;
  is_active_licensee: boolean;
  normalized_phone?: string;
  location_uuids: string[];
  objectID: string;
  id?: string;
  memberships?: UserMembership[];
};

export type MemberMapperKeys =
  | 'uuid'
  | 'name'
  | 'location_uuids'
  | 'email'
  | 'companies'
  | 'past_companies'
  | 'status'
  | 'kind'
  | 'normalized_phone'
  | 'is_licensee'
  | 'objectID'
  | 'company_names'
  | 'is_active_licensee'
  | 'memberships';

export type MemberSearchResultItem = Pick<AlgoliaMember, MemberMapperKeys> & {
  isPrimary: AlgoliaMember['is_licensee'];
  spaces?: MemberCompany['spaces']; // added in SEARCH_ENRICHMENT_USER_SPACES action.
};

export type PrimaryMember = {
  name?: string;
  email?: string;
  status?: Status;
  uuid?: string;
  phone?: string;
};

export type MimoDates = {
  min_movein_date: string | null;
  max_moveout_date: string | null;
};

export type AlgoliaCompany = {
  uuid: string;
  name: string;
  status: Status;
  printer_pin: string;
  is_migrated: boolean;
  potential: boolean;
  printer_login: number;
  enterprise: boolean;
  location_uuids: string[];
  aliases: string[];
  primary_member?: PrimaryMember;
  shortCode?: string;
  short_code?: string;
  objectID: string;
  spaces?: Space[];
  desks?: { [key: string]: number };
  mimo?: { [key: string]: MimoDates };
  system_of_record?: string;
};

export type CompanyMapperKeys =
  | 'uuid'
  | 'name'
  | 'location_uuids'
  | 'spaces'
  | 'aliases'
  | 'is_migrated'
  | 'status'
  | 'primary_member'
  | 'enterprise'
  | 'short_code'
  | 'objectID'
  | 'potential'
  | 'desks'
  | 'mimo'
  | 'system_of_record';

export type CompanySearchResultItem = Pick<AlgoliaCompany, CompanyMapperKeys>;

export type KeycardSearchResultItem = {
  id: string;
  hotstamp: string;
  user: {
    email: string;
    name: string;
    uuid: string;
    location_uuid: string;
  };
};

export type PrivateMemberSearchResultItem = SearchUser;

export type ContactSearchResultItem = {
  uuid: string;
  objectID: string;
  company_name: string;
  contact_name: string;
  contact_uuid: string;
  email: string;
  salesforce_id?: string;
  type: string;
};

export type GuestSearchResultItem = GuestVisit | CheckedInGuestVisit;

type CountryGeo = {
  id: string;
  name: string;
  name_abbreviation: string | null;
  latitude: string | null;
  longitude: string | null;
  iso: string | null;
};

type MatchPattern = {
  value: string;
  matchLevel: string;
  matchedWords: string[];
  fullyHighlighted?: boolean;
};

type HighlightedKeys =
  | 'id'
  | 'code'
  | 'default_name'
  | 'city'
  | 'state'
  | 'zip'
  | 'address'
  | 'countrygeo'
  | 'marketgeo';

type HighlightResult = {
  [key in HighlightedKeys]: MatchPattern;
} & {
  state_name?: MatchPattern;
};

export type BuildingItem = {
  id: string;
  code: string;
  default_name: string;
  default_locale: string;
  city: string;
  state: string;
  state_name?: string;
  zip: string;
  opened_on: string;
  is_open: boolean;
  address: string;
  line1: string;
  line2: string;
  access_type: string;
  managed_by?: string[];
  opened_on_timestamp: number;
  sold_on: string;
  marketed_on: string;
  time_zone: string;
  slug: string;
  is_published: boolean;
  is_not_physical: boolean;
  image_url?: string;
  on_demand_enabled: boolean;
  countrygeo: CountryGeo;
  marketgeo: CountryGeo;
  _geoloc: Omit<GeoLocation, 'radius'>;
  objectID: string;
  _highlightResult: HighlightResult;
};

type BuildingSearchKeys = 'id' | 'code' | 'default_name' | 'is_open';

export type BuildingSearchResultItem = Pick<BuildingItem, BuildingSearchKeys>;

export type SearchResultItem =
  | CompanySearchResultItem
  | MemberSearchResultItem
  | KeycardSearchResultItem
  | ContactSearchResultItem
  | GuestSearchResultItem
  | BuildingSearchResultItem
  | PrivateMemberSearchResultItem;

export type SearchResultGroup<T extends SearchResultItem> = {
  totalCount: number;
  items: Array<T>;
  facets?: {} | null | undefined;
};

export type FormattedResultObject<K extends SearchableEntity, T> = {
  key: K;
  name: string;
  color: string;
  results: Array<T>;
  resultCount: number;
};

export type CompanyFormattedResultObject = FormattedResultObject<
  SearchableEntity.COMPANIES,
  CompanySearchResultItem
>;
export type MemberFormattedResultObject = FormattedResultObject<
  SearchableEntity.MEMBERS,
  MemberSearchResultItem
>;
export type KeycardFormattedResultObject = FormattedResultObject<
  SearchableEntity.KEYCARDS,
  KeycardSearchResultItem
>;
export type GuestFormattedResultObject = FormattedResultObject<
  SearchableEntity.GUESTS,
  GuestSearchResultItem
>;

export type AdapterResult = Partial<Record<EntityType, SearchResultGroup<SearchResultItem>>>;

export type SuccessResult<T extends SearchResultItem> = {
  success: true;
  value: SearchResultGroup<T>;
};

export type FailedResult = {
  success: false;
  value: Error;
};

// Using shape because Flow has some bugs when dealing with 'empty' objects: https://github.com/facebook/flow/issues/2977#issuecomment-352236384
export type SearchResult = Partial<{
  company: SuccessResult<CompanySearchResultItem> | FailedResult;
  member: SuccessResult<MemberSearchResultItem> | FailedResult;
  keycard: SuccessResult<KeycardSearchResultItem> | FailedResult;
  contact: SuccessResult<ContactSearchResultItem> | FailedResult;
  guest: SuccessResult<GuestSearchResultItem> | FailedResult;
  building: SuccessResult<BuildingSearchResultItem> | FailedResult;
  privateMember: SuccessResult<PrivateMemberSearchResultItem> | FailedResult;
}>;
